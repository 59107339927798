@tailwind base;
@tailwind components;
@tailwind utilities;


/* For transparent background of scroll-bar */
body {
    overflow: overlay;
}


/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

* {
    transition-property: background-color, padding, z-index, box-shadow, transform, opacity !important;
    transition-duration: 200ms !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(68, 68, 68, 0);
    border-radius: 0.1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0);
}

::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
}